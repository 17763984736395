<template>
  <main-document :tabs="tabs"> </main-document>
</template>

<script>

import recentsController from '../../recentsController'
import themeController from '@/themeController'

export default {
  components: {
    MainDocument: () => import('@/components/main-document')
  },
  activated () {
    this.setModule()
  },
  mounted () {
    //   this.setRecentControllerScope(this.$route.params.id)
  },
  computed: {
  },
  data () {
    return {
      themeController: themeController,
      recentsController: recentsController,
      tabs: [
        {
          documentType: 'message-unassigned-detail',
          title: this.$t('t.Infos'),
          icon: '',
          isLoaded: false,
          isNew: false,
          isPristine: true,
          canSave: true,
          canUpdate: true,
          canClose: false,
          cancel: undefined,
          showComment: false
        }
      ]
    }
  },
  methods: {
    setRecentControllerScope (id) {
      recentsController.scope = {
        documentType: 'message-unassigned',
        documentId: id
      }
    },
    setModule () {
      const moduleData = {
        name: this.$t('t.MessageUnassigned')
      }

      this.$store.commit('setModule', moduleData)
    }
  }
}
</script>

<style lang="stylus" scoped></style>
